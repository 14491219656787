import React, { useEffect, useState, useContext } from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import './AutoCompleteWithChips.scss';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { GlobalContext } from '../../App';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IconButton, Tooltip } from '@mui/material';

export const AutoCompleteWithChips = (props) => {
  const [options, setOptions] = useState("");
  const { state } = useContext(GlobalContext);
  const {
    label,
    required,
    value = [],
    onChange,
    option = "email",
    multi,
    disable,
    description
  } = props

  const style = {
    control: (base) => ({
      ...base,
      outline: "none",
      border: "none",
      borderRadius: '0.3rem',
      boxShadow: '0px 1px 1px #eee inset, 0px -1px 1px #eee inset, 1px 0px 1px #eee inset, 1px 0px 1px #eee'
    }),
    menu: (base) => ({
      ...base
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "#2A7DE1",
      borderRadius: "0.3rem",
      color: "#fff"
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "#fff"
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: '#000',
    })
  };

  const dynamicSort = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      let result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
      return result * sortOrder;
    }
  };

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (option === "os") {
        let osList = state.awsOs?.data?.map(os => (
          {
            label: os.os_type,
            value: os.os_type
          }
        ));
        osList.sort(dynamicSort("value"));
        setOptions(osList);
      }
      else if (option === "bu") {
        let buList = state.bizUnits?.data?.map(bu => (
          {
            label: bu.BusinessUnit,
            value: bu.BusinessUnit
          }
        ))
        buList.sort(dynamicSort("value"));
        setOptions(buList);
      }
      else if (option === "email") {
        setOptions(null);
      }
      else if (option === "account") {
        let accountList = state.awsAccounts?.data?.map(id => (
          {
            label: `${id.AccountID} - ${id.AccountName}`,
            value: id.AccountID
          }
        ));
        accountList.sort(dynamicSort("value"))
        setOptions(accountList);
      }
      else if (option === "env") {
        envTypeOptions.sort(dynamicSort("value"));
        setOptions(envTypeOptions);
      }
      else if (option === "deployment") {
        deploymentZone.sort(dynamicSort("value"))
        setOptions(deploymentZone);
      }
      else if (option === "region") {
        let regionsList = state.awsRegions?.data?.map(region => (
          {
            label: region.region_name,
            value: region.region_name
          }
        ))
        regionsList.sort(dynamicSort("value"));
        setOptions(regionsList);
      }
      else if (option === "frequency") {
        setOptions(reportFrequency);
      }
      else if(option === "instanceTypes") {
        let instanceTypeList = state.instanceTypes?.data?.map(type => (
          {
            label: type.instance_type,
            value: type.instance_type
          }
        ))
        instanceTypeList.sort(dynamicSort("value"));
        setOptions(instanceTypeList);
      }
      else if (option === "accountStatus") {
        accountStatusOptions.sort(dynamicSort("value"));
        setOptions(accountStatusOptions);
      }
      else if (option === "defaultGIVersion") {
        defaultGIVersion.sort(dynamicSort("value"));
        setOptions(defaultGIVersion);
      }
      else if (option === "ou") {
        let ouList = state.ouDeatils?.data?.map(ou => (
          {
            label: ou.ou_name,
            value: ou.ou_name
          }
        ))
        ouList.sort(dynamicSort("value"));
        setOptions(ouList);
      }
    }

    return () => {
      suscribe = false;
    }
  }, [option, state.awsAccounts, state.awsRegions, state.bizUnits, state.awsOs, state.instanceTypes, state.ouDeatils]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="autoCompleteWithChips">
      <label className="mb-2">
        {label}
        {required ? <span className="text-danger">*</span> : null}
        {
          description ? (
            <Tooltip title={description} placement="top">
              <IconButton size="small" style={{ color: "#616161" }} disableRipple>
                <InfoOutlined color='inherit' fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )
            : null
        }
      </label>
      {
        option === "email" || option === "env" || option === "deployment" ?
          <CreatableSelect
            options={options}
            isMulti={multi ? false : true}
            styles={style}
            placeholder={''}
            components={{ DropdownIndicator }}
            value={value}
            onChange={onChange}
            isDisabled={disable}
          />
          :
          <Select
            options={options}
            isMulti={multi ? false : true}
            styles={style}
            placeholder={''}
            components={{ DropdownIndicator }}
            value={value}
            onChange={onChange}
            isDisabled={disable}
          />
      }
    </div>
  );
};

export default AutoCompleteWithChips;

const deploymentZone = [
  {
    label: 'private',
    value: 'private'
  },
  {
    label: 'dmz',
    value: 'dmz'
  }
];

const reportFrequency = [
  {
    label: 'daily',
    value: 'daily'
  },
  {
    label: 'weekly',
    value: 'weekly'
  },
  {
    label: 'monthly',
    value: 'monthly'
  }
];

const envTypeOptions = [
  {
    label: 'prod',
    value: 'prod'
  },
  {
    label: 'uat',
    value: 'uat'
  },
  {
    label: 'dev',
    value: 'dev'
  },
  {
    label: 'accept',
    value: 'accept'
  },
  {
    label: 'test',
    value: 'test'
  },
  {
    label: 'pre-prod',
    value: 'pre-prod'
  },
  {
    label: 'shared',
    value: 'shared'
  }
];

const accountStatusOptions = [
  {
    label: 'ACTIVE',
    value: 'ACTIVE'
  },
  {
    label: 'SUSPENDED',
    value: 'SUSPENDED'
  },
  {
    label: 'UNMANAGED',
    value: 'UNMANAGED'
  },
  {
    label: 'DELETED',
    value: 'DELETED'
  }
]

const defaultGIVersion = [
  {
    label: "gi_v2",
    value: "gi_v2"
  },
  {
    label: "gi_v3",
    value: "gi_v3"
  }
]
