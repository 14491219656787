import React, { useEffect, useState } from 'react';
import {
  IconButton,
  Button
} from '@mui/material';
import clsx from 'clsx';
// import { makeStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import hamImg from '../../assets/icons/ham.svg';
import logoImg from '../../assets/Images/logo.svg';
import veriskLogo from '../../assets/Images/verisk_logo_white.svg';
import LHSimage from './LHSimage';
import './Home.scss';
import DrawerContext from '../../context/DrawerContext';
import Router from '../../Router';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../App';
import { useContext } from 'react';
import { pagesData } from '../../App';
import useApiCalls from '../../hooks/useApiCalls';
import MenuIcon from '@mui/icons-material/Menu';
import { BU_USER, READ_ONLY_USER, SUPER_USER, USER } from '../../roles';

const drawerWidth = 270;
const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    height: '75px',
    paddingTop: '5px',
    zIndex: 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
    marginLeft: `calc(${drawerWidth}px - 205px)`,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonOpen: {
    marginRight: 36,
    marginLeft: 15,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6)
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    whiteSpace: 'wrap',
    marginBottom: '7px',
    width: '270px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1
  },
}));

export default function Home(props) {
  // const classes = useStyles();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [user, setUser] = useState({});
  const { state, dispatch } = useContext(GlobalContext);
  const drawerData = pagesData;
  const setDrawerState = useContext(DrawerContext).setDrawerState;
  const drawerState = useContext(DrawerContext).drawerState;
  const user1 = useContext(DrawerContext).userInfo
  const {
    getTableData
  } = useApiCalls();

  useEffect(() => {
    let suscribe = true;
    if (suscribe) {
      if (state.awsAccounts?.data?.length < 1) {
        getTableData('accounts', 'SET_AWS_ACCOUNTS');
      }
      if (state.awsRegions?.data?.length < 1) {
        getTableData('regions', 'SET_AWS_REGIONS');
      }
      if (state.awsOs?.data?.length < 1) {
        getTableData('os', 'SET_AWS_OS');
      }
      if (state.bizUnits?.data?.length < 1) {
        getTableData('businessunit', 'SET_BU_LIST');
      }
      if (state.instanceTypes?.data?.length < 1) {
        getTableData('instancetypes', 'Set_Instance_Types_List');
      }
      if (state.ouDeatils?.data?.length < 1) {
        getTableData('oudetails', 'SET_OU_DETAILS');
      }
    }

    return () => {
      suscribe = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, []);

  useEffect(() => {
    setUser(user1);
    let userInfo = {
      name: user?.claims?.name,
      roles:user?.claims?.groups
    };
    

    if( userInfo?.roles && (userInfo?.roles?.some(item=>item?.includes("superuser")))){
      dispatch({ type: 'SET_USER', payload: { name: userInfo.name, roles: userInfo.roles, userRole: SUPER_USER } });
    }
    else if ( userInfo?.roles && (userInfo?.roles?.some(item=>(item?.includes("users_hovadmin") && !item?.includes("readonly"))))) {
      dispatch({ type: 'SET_USER', payload: { name: userInfo.name, roles: userInfo.roles, userRole:BU_USER } });
    }
    else if(userInfo?.roles && (userInfo?.roles?.some(item=>item?.includes("readonly")))){
      dispatch({ type: 'SET_USER', payload: { name: userInfo.name, roles: userInfo.roles, userRole:READ_ONLY_USER } });
    }
    else{
      dispatch({ type: 'SET_USER', payload: { name: userInfo.name, roles: userInfo.roles, userRole:USER } });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);


  const handleSetCurrentTab = (e) => {
    let id = e.target.id;
    if (id) {
      id = id.split('-')[0];
    }
    let currTab = e.target.innerText || id;
    currTab = currTab.toUpperCase();
    document.querySelectorAll('.drawer-tab').forEach((tab) => {
      if (tab.innerText === currTab) {
        tab.classList.add('active-tab');
      } else {
        tab.classList.remove('active-tab');
      }
    });
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
    setDrawerState(!drawerState);
  };

  const handleLogout = () => {
    props.logout();
  };

  return (
    <div className={classes.root + ` home`}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerState,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.menuButtonOpen]: drawerState
            })}
            size="large"
            disableRipple
            disableFocusRipple
          >
            <MenuIcon fontSize='inherit' />
          </IconButton>
          <img
            src={veriskLogo}
            alt="Verisk Logo"
            className='veriskLogo'
          />

          <div
            className={clsx({
              'ml-auto': true || location.pathname !== '/accounts',
              'user-info-container': true,
            })}
          >
            <div className="user-name">{user?.claims?.name}</div>
            <div className="user-role"></div>
          </div>
          <Button
            color="primary"
            className="logout"
            onClick={handleLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerState,
          [classes.drawerClose]: !drawerState,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerState,
            [classes.drawerClose]: !drawerState,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <div>
            {' '}
          </div>
          <div className="logo-container">
            <div className="logo-img">
              <img src={logoImg} alt="HOV logo" />
            </div>
            <div className="logo-text">
              <Typography variant="h6" className="logo-name">HOV Administration Portal</Typography>
            </div>
          </div>
        </div>
        <List>
          {drawerData.map((drawerItem, index) => {
            // const currentUserRoles = state.user.roles;
            // const allowedRolesForCurrentTab = drawerItem.roles;

            // const hasValidPermission = allowedRolesForCurrentTab.some((role) =>
            //   currentUserRoles.includes(role)
            // );
            // if (!hasValidPermission) return;

            return (
              <div key={`tabbbbb__${index}`}>
                <div className="comp-fff">
                  {drawerItem.route === location.pathname ? (
                    <div className="top"></div>
                  ) : null}
                </div>
                <Link to={drawerItem.route} onClick={handleSetCurrentTab}>
                  <ListItem
                    button
                    key={drawerItem.title}
                    className={
                      ` drawer-tab ` +
                      (drawerItem.route === location.pathname
                        ? 'active-tab'
                        : '')
                    }
                    onClick={() => setDrawerState(false)}
                  >
                    <ListItemIcon>
                      <LHSimage
                        index={index}
                        id={`${drawerItem.title}-${index}`}
                        isActive={drawerItem.route === location.pathname}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={drawerItem.title}
                      className="drawer-icon-text"
                    />
                  </ListItem>
                </Link>
                <div className="comp-fff">
                  {drawerItem.route === location.pathname ? (
                    <div className="bot"></div>
                  ) : null}
                </div>
              </div>
            );
          })}
        </List>
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Router />
      </main>
    </div>
  );
}